import { ResponseProps } from 'services/services.types';

export enum DynamicLinkActions {
  APPOINTMENT_CONFIRMATION = 'appointment-confirmation',
  BILLING = 'billing',
  DASHBOARD = 'dashboard',
  WAITING_ROOM = 'waiting-room'
}

export interface GenerateDynamicLinkQueueReqProps {
  accessToken: string;
  action: DynamicLinkActions;
  appointmentId: string;
  token: string;
}

export type GenerateDynamicLinkResProps = ResponseProps<{ dynamicLink: string }>;

export interface GenerateDynamicLinkReqProps {
  accessToken?: string;
  action: DynamicLinkActions;
  appointmentId?: string;
  doctorId?: string;
  expiredToken?: string;
  src: 'signUp' | 'appMobile';
}

export type GetTokenResProps = ResponseProps<{ link: string }>;

export interface VerifyIdentityReqProps {
  accessToken?: string;
  governmentIssuedId: string;
  selfPortrait?: string;
}

export type VerifyIdentityCrosscheckResProps = ResponseProps<{
  errors: string[];
  isVerified: boolean;
  warnings: string[];
}>;

export type GetSignableDocumentsReqProps = {
  accessToken?: string;
} | void;

export type SignableDocuments =
  | 'insurance-terms'
  | 'medicaid-waiver-terms'
  | 'hipaa-marketing-agreement'
  | 'kroger-terms';
export type GetSignableDocumentsResProps = ResponseProps<
  [{ documentId: string; html: string; type: SignableDocuments }]
>;

export interface SignDocumentReqProps {
  accessToken?: string;
  templateId: string;
}

export type SignDocumentResProps = ResponseProps<{ success: boolean }>;

export interface UnSignDocumentReqProps {
  documentId: string;
}

export type UnSignDocumentResProps = ResponseProps<{ success: boolean }>;

export type GetGlobalMessagesResProps = {
  frontDescMessage: string;
  messageAppointmentDescription: string;
  messageAppointmentText: string;
  videoAppointmentDescription: string;
  videoAppointmentText: string;
};
