import { Common } from '@thecvlb/design-system';

import Collapse from 'shared/Collapse';
import Loader from 'shared/Loader';

import { useGetLifeMDPlusPlan } from 'hooks/useCreateAppointmentExtended';

import { SubscriptionRequiredProps } from './subscriptionRequired.types';

const ITEMS = [
  {
    title: 'How does the LifeMD+ membership work?',
    content: (
      <span className="flex flex-col gap-2">
        <span>
          A LifeMD+ membership unlocks access to a comprehensive suite of healthcare benefits
          designed to simplify and enhance your health journey. With 24/7 access to healthcare
          providers — along with same-day prescription refills, exclusive pricing on wellness
          resources, and convenient lab testing options — LifeMD+ makes it easier than ever to
          prioritize your health.
        </span>
        <span>Please see the next question for more details on costs associated with care.</span>
      </span>
    )
  },
  {
    title: 'What is the cost of care?',
    content: (
      <>
        While the LifeMD+ membership comes with a range of valuable benefits, additional charges may
        apply for consultations, prescriptions, and/or lab work based on your needs and insurance
        coverage. Here’s a summary of what to expect:
        <ul className="flex list-inside list-disc flex-col pl-4 pt-4">
          <li>
            Video visits with licensed healthcare providers, available within 1 hour, are $50
            (cash), or the cost of your co-pay* if you use your insurance.
          </li>
          <li>
            Message-based consults to address common health issues, including UTIs, sinus
            infections, pink eye, and more are $20 (cash).
          </li>
          <li>
            Prescription refill orders (for medication previously prescribed by a LifeMD-affiliated
            provider) are $5 (cash) for the message-based consultation fee. The free LifeMD
            prescription discount card can be presented at over 60,000 pharmacies in the U.S. to
            save up to 92% on most prescription medications.{' '}
          </li>
          <li>
            Annual and specialty lab testing through Quest Diagnostics or Labcorp is the
            responsibility of the patient but is often covered by insurance.*
          </li>
        </ul>
        <p className="pl-4 pt-4 text-base italic md:pl-8 md:text-sm">
          *Costs through insurance vary based on your coverage and are determined by your insurance
          provider.
        </p>
      </>
    )
  },
  {
    title: 'What is the cost of a LifeMD+ membership after the first month?',
    content:
      ' LifeMD is a monthly subscription program. Your credit card will be charged $19 at the beginning of each billing cycle, and the subscription will automatically renew unless you choose to cancel. You can manage or cancel your subscription at any time through your account settings in the LifeMD patient portal.'
  },
  {
    title: 'Can I get an appointment on weekends or holidays?',
    content:
      'Yes! LifeMD+ offers 24/7 access to licensed healthcare providers, including weekends and holidays. Appointments are available within an hour, ensuring you can receive care when you need it most.'
  },
  {
    title: 'What if I need a prescription renewal quickly?',
    content: (
      <span className="flex flex-col gap-2">
        <span>
          LifeMD+ offers fast solutions for prescriptions that have been previously filled by a
          LifeMD-affiliated provider. You can request a prescription renewal through a
          message-based, asyc consultation. This consultation costs $5 (cash).
        </span>
        <span>
          If a prescription is deemed appropriate, it will be sent directly to your local pharmacy
          the same day, if requested during LifeMD service hours (7am-9pm ET).
        </span>
      </span>
    )
  },
  {
    title: 'Are lab tests available through LifeMD+?',
    content:
      'Yes. LifeMD+ members benefit from annual and specialty lab testing through Labcorp or Quest Diagnostics to help you track and manage your health markers. Your LifeMD care team can send a lab order directly to your LifeMD patient portal.'
  }
];

const SubscriptionRequired: React.FC<SubscriptionRequiredProps> = ({
  category,
  callMethod,
  onSelect
}) => {
  const { lifeMDPlusPlan, defaultPricePoint, isFetching } = useGetLifeMDPlusPlan();

  const pricePerPeriod = (price: number, period: number) => {
    return period === 1 ? `${price}/mo` : `${price}/every $period mo`;
  };

  const handleSelect = () => {
    if (!lifeMDPlusPlan || !defaultPricePoint) throw new Error('No plan found');
    onSelect({
      callMethod,
      planId: lifeMDPlusPlan._id,
      pricePoint: defaultPricePoint
    });
  };

  const planBonuses = (
    defaultPricePoint?.additionalInfo ??
    lifeMDPlusPlan?.additionalInfo ??
    []
  ).map((info) => info.question);

  return (
    <div className="flex flex-col gap-6">
      <Loader isVisible={isFetching} />
      <div className="flex flex-col gap-2">
        <Common.ColorTag color="primary" icon="check" text={category} />
        <h1 className="text-m2xl font-bold text-primary-700">
          Welcome to the future of personalized care
        </h1>
      </div>
      <div className="flex gap-4 rounded-2xl border-2 border-blue bg-blue-50 p-6">
        <div className="flex flex-1 flex-col">
          <p className="font-semibold">{lifeMDPlusPlan?.planName}</p>
          <p className="mt-1 text-mSm text-primary-400">
            <span className="text-primary-200 line-through">
              {pricePerPeriod(
                +(defaultPricePoint?.totalCost ?? 0),
                defaultPricePoint?.paymentInterval.qty ?? 1
              )}
            </span>{' '}
            Free for your first month
          </p>
          <p className="mt-2 text-mSm">Discounted appointments, annual labs and more.</p>
          <ul className="mt-4 flex flex-col gap-2">
            {planBonuses.map((item) => (
              <li className="flex gap-2 text-mSm" key={item}>
                <Common.Icon className="flex-none text-primary-400" name="check" />
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div>
        <h2 className="text-xl font-semibold text-primary-700">Frequently Asked Questions</h2>
        {ITEMS.map((item) => (
          <Collapse key={item.title} title={item.title}>
            {item.content}
          </Collapse>
        ))}
      </div>
      <Common.Button className="m-auto" color="blue" fullWidthOnMobile onClick={handleSelect}>
        {callMethod === 'video' ? 'Continue' : 'Continue to payment'}
      </Common.Button>
    </div>
  );
};

export default SubscriptionRequired;
