import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import UpdateBirthday from 'modals/UpdateBirthday';

import { ErrorMessagesProps } from './errorMessages.types';

const ErrorMessages: React.FC<ErrorMessagesProps> = ({
  filesIdentified,
  filesVerified,
  mergedErrors,
  verifyIdentity
}) => {
  const [isOpenUpdateBirthday, toggleIsOpenUpdateBirthday] = useToggle(false);

  const handleOnCloseUpdateBirthday = (triggerVouched?: boolean) => {
    if (triggerVouched) {
      verifyIdentity?.();
    }
    toggleIsOpenUpdateBirthday();
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <UpdateBirthday isOpen={isOpenUpdateBirthday} onClose={handleOnCloseUpdateBirthday} />
      {filesIdentified && !filesVerified && !mergedErrors.length && (
        <Common.Alert type="warning" colorableBackground>
          <b>We had trouble verifying your identity.</b> Our team can attempt to verify them
          manually, or you can try another photo.
        </Common.Alert>
      )}
      {mergedErrors
        .reduce(
          (acc, current) => {
            const isDuplicate = acc.some((error) => error.message === current.message);
            return isDuplicate ? acc : [...acc, current];
          },
          [] as { message: string; type: string }[]
        )
        .map((error) => (
          <Common.Alert key={error.type} type="error" colorableBackground>
            <b>Error:</b> {error.message}
            {error.type === 'BirthDateMatchError' && verifyIdentity && (
              <Common.Button
                className="mt-2"
                color="white-alt"
                onClick={toggleIsOpenUpdateBirthday}
              >
                Update birthdate
              </Common.Button>
            )}
          </Common.Alert>
        ))}
    </div>
  );
};

export default ErrorMessages;
