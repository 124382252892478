import { FlowTypes, PathName, PlanTypes, SignUpSteps } from 'utils/enums';

export const MIF_STEPS: SignUpSteps[] = [
  SignUpSteps.Medications,
  SignUpSteps.Allergies,
  SignUpSteps.HealthConditions,
  SignUpSteps.ChronicDiseases,
  SignUpSteps.WomensHealth,
  SignUpSteps.SocialHistory,
  SignUpSteps.MifSummary
];

const B1_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.Intro,
  // SignUpSteps.AppointmentCategory,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  SignUpSteps.Confirmation
];

const SPECIFIC_DRUG_PROVIDER_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  SignUpSteps.Confirmation
];

const B1B_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.PlanSelect,
  SignUpSteps.Intro,
  // SignUpSteps.AppointmentCategory,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  SignUpSteps.Confirmation
];

const B1X_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.Intro,
  // SignUpSteps.AppointmentCategory,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  SignUpSteps.SCResults
];

const B1N_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.AppointmentDetails,
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.PlanSelect,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  SignUpSteps.SCResults
];

const B1XC_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.Intro,
  // SignUpSteps.AppointmentCategory,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy
];

const B2_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.ChooseProvider,
  SignUpSteps.PlanSelect,
  SignUpSteps.Payment,
  SignUpSteps.CreateNewUser,
  SignUpSteps.TwoOptionsFinish
];

const B2C_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.ChooseProvider,
  SignUpSteps.PlanSelect,
  SignUpSteps.Payment,
  SignUpSteps.CreateNewUser,
  SignUpSteps.TwoOptionsFinish
];

const B3_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.PlanSelect,
  SignUpSteps.ChooseProvider,
  SignUpSteps.Payment,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Pharmacy,
  SignUpSteps.Confirmation
];

const B3C_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.PlanSelect,
  SignUpSteps.ChooseProvider,
  SignUpSteps.Payment,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Pharmacy,
  SignUpSteps.Confirmation
];

const Q1_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.Intro,
  SignUpSteps.InitialInfo,
  SignUpSteps.ChooseProvider,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  SignUpSteps.SCResults,
  SignUpSteps.SwitchToApp
];

const Q2_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.Intro,
  SignUpSteps.InitialInfo,
  SignUpSteps.ChooseProvider,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.CreateNewUser,
  SignUpSteps.PlanSelect,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  SignUpSteps.SCResults,
  SignUpSteps.SwitchToApp
];

const I1_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.InitialInfo,
  SignUpSteps.ChooseProvider,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.CreateNewUser,
  SignUpSteps.PaymentOptions,
  SignUpSteps.PlanSelect,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  SignUpSteps.SCResults,
  SignUpSteps.SwitchToApp
];

const HW_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.SCResults
];

const HRT_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Confirmation
];

const WEIGHT_MANAGEMENT_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.State,
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.InsuranceType,
  SignUpSteps.WMCongrats,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMGallStonesQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.PreferredMedication,
  SignUpSteps.MarketingLowerPrice,
  SignUpSteps.MarketingWorkingOnApproval,
  SignUpSteps.MarketingCompare,
  SignUpSteps.MarketingRatedNumberOne,
  SignUpSteps.PlanSelect,
  // SignUpSteps.MarketingMoneyBackGuarantee,
  SignUpSteps.NoticeForCalifornia,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

const WEIGHT_MANAGEMENT_EXTENDED_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.State,
  SignUpSteps.WMWeightLoseWithoutUncertainty,
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.InsuranceType,
  SignUpSteps.WMCongrats,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMGallStonesQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.PreferredMedication,
  SignUpSteps.MarketingCompare,
  SignUpSteps.MarketingRatedNumberOne,
  SignUpSteps.PlanSelect,
  SignUpSteps.NoticeForCalifornia,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

const WEIGHT_MANAGEMENT_OPTAVIA_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.State,
  SignUpSteps.WMWeightLoseWithoutUncertainty,
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.InsuranceType,
  SignUpSteps.WMCongrats,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.Agreement,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMGallStonesQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.PreferredMedication,
  SignUpSteps.MarketingCompare,
  SignUpSteps.MarketingRatedNumberOne,
  SignUpSteps.PlanSelect,
  SignUpSteps.NoticeForCalifornia,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

const WEIGHT_MANAGEMENT_BLUE_LINE_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.BlueLineIntro,
  SignUpSteps.State,
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMCongrats,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMGallStonesQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.YourWeightLossProgram,
  // SignUpSteps.PreferredMedication,
  SignUpSteps.MarketingCompare,
  SignUpSteps.MarketingRatedNumberOne,
  SignUpSteps.PlanSelect,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

const WEIGHT_MANAGEMENT_ASYNC_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.State,
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMKnowledgeAboutGLP1,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.InsuranceType,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMGallStonesQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.WMCongrats,
  SignUpSteps.PreferredMedication,
  SignUpSteps.MarketingLowerPrice,
  SignUpSteps.MarketingWorkingOnApproval,
  SignUpSteps.MarketingCompare,
  SignUpSteps.MarketingRatedNumberOne,
  SignUpSteps.ShouldUseInsurance,
  SignUpSteps.PlanSelect,
  SignUpSteps.NoticeForCalifornia,
  // SignUpSteps.MarketingMoneyBackGuarantee,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

const WEIGHT_MANAGEMENT_BALLAD_HEALTH_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.State,
  SignUpSteps.WMWeightLoseAim,
  // SignUpSteps.WMKnowledgeAboutGLP1,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  // SignUpSteps.InsuranceType,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMGallStonesQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  // SignUpSteps.WMCongrats,
  // SignUpSteps.PreferredMedication,
  // SignUpSteps.MarketingLowerPrice,
  // SignUpSteps.MarketingWorkingOnApproval,
  SignUpSteps.MarketingCompare,
  SignUpSteps.MarketingRatedNumberOne,
  // SignUpSteps.ShouldUseInsurance,
  SignUpSteps.PlanSelect,
  SignUpSteps.NoticeForCalifornia,
  // SignUpSteps.MarketingMoneyBackGuarantee,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

const WEIGHT_MANAGEMENT_MEDICATIONS_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.State,
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMKnowledgeAboutGLP1,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.InsuranceType,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMGallStonesQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.WMCongrats,
  SignUpSteps.PreferredMedication,
  SignUpSteps.MarketingLowerPrice,
  SignUpSteps.MarketingWorkingOnApproval,
  SignUpSteps.MarketingCompare,
  SignUpSteps.MarketingRatedNumberOne,
  SignUpSteps.ShouldUseInsurance,
  SignUpSteps.PlanSelect,
  SignUpSteps.NoticeForCalifornia,
  // SignUpSteps.MarketingMoneyBackGuarantee,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

const WEIGHT_MANAGEMENT_INSURANCE_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.State,
  SignUpSteps.WMWeightLoseWithoutUncertainty,
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.InsuranceType,
  SignUpSteps.WMCongrats,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMGallStonesQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  // SignUpSteps.YourWeightLossProgram,
  SignUpSteps.PreferredMedication,
  SignUpSteps.MarketingLowerPrice,
  SignUpSteps.MarketingWorkingOnApproval,
  SignUpSteps.MarketingCompare,
  // SignUpSteps.MarketingRatedNumberOne,
  // SignUpSteps.MarketingMoneyBackGuarantee,
  SignUpSteps.NoticeForCalifornia,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WithOrWithoutInsurance,
  SignUpSteps.Insurance,
  SignUpSteps.CheckPrescriptionCoverage,
  SignUpSteps.PlanSelect,
  // SignUpSteps.VerifyIdentity,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

const WEIGHT_MANAGEMENT_INSURANCE_ALT_FLOW_STEPS = [
  SignUpSteps.State,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMDOB,
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.InsuranceType,
  SignUpSteps.InsuranceAppointmentsAndMedications,
  SignUpSteps.InsuranceForAppointments,
  SignUpSteps.Insurance,
  SignUpSteps.InsuranceMedicationsToBeDiscussed,
  SignUpSteps.InsuranceForMedications,
  SignUpSteps.InsuranceLowCostOptions,
  SignUpSteps.WMCongrats,
  SignUpSteps.WMAddress,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMGallStonesQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.MarketingCompare,
  SignUpSteps.NoticeForCalifornia,
  SignUpSteps.PlanSelect,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

const WEIGHT_MANAGEMENT_INSURANCE_DISCOVERY_FLOW_STEPS = [
  SignUpSteps.MarketingVideo,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMDOB,
  SignUpSteps.InsuranceForAppointments,
  SignUpSteps.InsuranceMedicationsToBeDiscussed,
  SignUpSteps.InsuranceForMedications,
  SignUpSteps.InsuranceLowCostOptions,
  SignUpSteps.WMCongrats,
  SignUpSteps.WMAddress,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMGallStonesQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.MarketingCompare,
  SignUpSteps.NoticeForCalifornia,
  SignUpSteps.PlanSelect,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

export const TRIPLE_THERAPY_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.State,
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMWhyTT,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMCongrats,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  // SignUpSteps.WMPregnantTryingToBecomePregnant,
  SignUpSteps.WMWhatNext,
  // SignUpSteps.WMBloodPressure,
  SignUpSteps.WMKidneyStones,
  SignUpSteps.WMPriorSeizures,
  SignUpSteps.WMGlaucoma,
  SignUpSteps.WMMAOInhibitors,
  SignUpSteps.WMLiverOrKidneyImpairment,
  SignUpSteps.WMLacticAcidosis,
  SignUpSteps.WMBupropion,
  SignUpSteps.WMSideEffectsWithMetforminBupropion,
  SignUpSteps.WMKetogenicDiet,
  SignUpSteps.BipolarDisorder,
  SignUpSteps.WMTTResults,
  SignUpSteps.WMLabWork,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.PlanSelect,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

export const FLOW_TYPES: { [key in FlowTypes]: SignUpSteps[] } = {
  [FlowTypes.BasicFlow]: B1_FLOW_STEPS,
  [FlowTypes.BasicFlowText]: B1B_FLOW_STEPS,
  [FlowTypes.BasicExtendedFlow]: B1X_FLOW_STEPS,
  [FlowTypes.BasicExtendedNewPlansFlow]: B1N_FLOW_STEPS,
  [FlowTypes.BasicExtendedDynamicFlow]: B1XC_FLOW_STEPS,
  [FlowTypes.ShortFlow]: B2_FLOW_STEPS,
  [FlowTypes.ShortFlowWithOptionalPopup]: B2C_FLOW_STEPS,
  [FlowTypes.MixedFlow]: B3_FLOW_STEPS,
  [FlowTypes.MixedFlowWithRequiredPopup]: B3C_FLOW_STEPS,
  [FlowTypes.LovazaFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.LovazaBFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.AcipHexFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.AcipHexBFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.AvodartFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.AvodartBFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.HumatinFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.HumatinBFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.BlueLineFlow]: WEIGHT_MANAGEMENT_BLUE_LINE_FLOW_STEPS,
  [FlowTypes.UTIFlow]: Q2_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowAsync]: WEIGHT_MANAGEMENT_ASYNC_FLOW_STEPS,
  [FlowTypes.WeightManagementFlow]: WEIGHT_MANAGEMENT_FLOW_STEPS,
  [FlowTypes.WeightManagementSpecific]: WEIGHT_MANAGEMENT_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowExtended]: WEIGHT_MANAGEMENT_EXTENDED_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowOptavia]: WEIGHT_MANAGEMENT_OPTAVIA_FLOW_STEPS,
  [FlowTypes.WeightManagementBalladHealth]: WEIGHT_MANAGEMENT_BALLAD_HEALTH_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowInsurance]: WEIGHT_MANAGEMENT_INSURANCE_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowInsuranceAlt]: WEIGHT_MANAGEMENT_INSURANCE_ALT_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowInsuranceDiscovery]:
    WEIGHT_MANAGEMENT_INSURANCE_DISCOVERY_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowInsuranceMedications]: WEIGHT_MANAGEMENT_MEDICATIONS_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowMedicaid]: WEIGHT_MANAGEMENT_FLOW_STEPS,
  [FlowTypes.QueueFlow]: Q1_FLOW_STEPS,
  [FlowTypes.QueueFlowWithNewPlans]: Q2_FLOW_STEPS,
  [FlowTypes.QueueFlowWithNewPlansAlt]: Q2_FLOW_STEPS,
  [FlowTypes.InsuranceFlow]: I1_FLOW_STEPS,
  [FlowTypes.InsuranceFlowAlt]: I1_FLOW_STEPS,
  [FlowTypes.HealthWarehouseFlow]: HW_FLOW_STEPS,
  [FlowTypes.HRTClubFlow]: HRT_FLOW_STEPS,
  [FlowTypes.TripleTherapy]: TRIPLE_THERAPY_FLOW_STEPS
};

export const SIGNUP_PATH = `${PathName.SignUp}/${PlanTypes.TotalCare}/${FlowTypes.QueueFlowWithNewPlansAlt}`;

export const SPECIFIC_DRUGS_FLOWS = [
  FlowTypes.LovazaFlow,
  FlowTypes.AcipHexFlow,
  FlowTypes.AvodartFlow,
  FlowTypes.HumatinFlow
];

export const SPECIFIC_DRUGS_ALT_FLOWS = [
  FlowTypes.LovazaBFlow,
  FlowTypes.AcipHexBFlow,
  FlowTypes.AvodartBFlow,
  FlowTypes.HumatinBFlow
];

export const SPECIFIC_APPOINTMENT_TYPES: { [key in FlowTypes]?: string } = {
  [FlowTypes.LovazaFlow]: 'Woodward - Lovaza',
  [FlowTypes.AcipHexFlow]: 'Woodward - AcipHex',
  [FlowTypes.AvodartFlow]: 'Woodward - Avodart',
  [FlowTypes.HumatinFlow]: 'Woodward - Humatin',
  [FlowTypes.LovazaBFlow]: 'Woodward - Lovaza',
  [FlowTypes.AcipHexBFlow]: 'Woodward - AcipHex',
  [FlowTypes.AvodartBFlow]: 'Woodward - Avodart',
  [FlowTypes.HumatinBFlow]: 'Woodward - Humatin'
};

export const WM_FLOWS = [
  FlowTypes.WeightManagementFlow,
  FlowTypes.WeightManagementFlowExtended,
  FlowTypes.WeightManagementFlowOptavia,
  FlowTypes.WeightManagementBalladHealth,
  FlowTypes.WeightManagementFlowInsurance,
  FlowTypes.WeightManagementFlowInsuranceAlt,
  FlowTypes.WeightManagementFlowInsuranceDiscovery,
  FlowTypes.WeightManagementFlowAsync,
  FlowTypes.WeightManagementFlowInsuranceMedications,
  FlowTypes.WeightManagementSpecific,
  FlowTypes.WeightManagementFlowMedicaid,
  FlowTypes.TripleTherapy,
  FlowTypes.BlueLineFlow
];

export const SPECIFIC_APPOINTMENT_CODES: { [key in FlowTypes]?: string } = {
  [FlowTypes.LovazaFlow]: 'WW-LOV-USA-0011',
  [FlowTypes.AcipHexFlow]: 'WW-ACI-USA-0032',
  [FlowTypes.AvodartFlow]: 'WW-AVO-USA-0006',
  [FlowTypes.HumatinFlow]: 'WT-HUM-USA-0005'
};

export const STATES_WHERE_ASYNC_FLOW_IS_PROHIBITED = [
  'Arkansas', // AR
  'District of Columbia', // DC
  'Delaware', // DE
  'Kansas', // KS
  'Mississippi', // MS
  'New Mexico', // NM
  'Virginia', // VA
  'West Virginia' // WV
];

export const STATES_WHERE_ONBOARDING_IS_NOT_ALLOWED = [
  'American Samoa',
  // 'District of Columbia',
  'Guam',
  'Northern Mariana Islands',
  'U.S. Virgin Islands'
];
